import request from '@/utils/request'


// 查询新闻评论回复举报列表
export function listReport(query) {
  return request({
    url: '/news/newsReport/list',
    method: 'get',
    params: query
  })
}

// 查询新闻评论回复举报分页
export function pageReport(query) {
  return request({
    url: '/news/newsReport/page',
    method: 'get',
    params: query
  })
}

// 查询新闻评论回复举报详细
export function getReport(data) {
  return request({
    url: '/news/newsReport/detail',
    method: 'get',
    params: data
  })
}

// 新增新闻评论回复举报
export function addReport(data) {
  return request({
    url: '/news/newsReport/add',
    method: 'post',
    data: data
  })
}

// 修改新闻评论回复举报
export function updateReport(data) {
  return request({
    url: '/news/newsReport/edit',
    method: 'post',
    data: data
  })
}

// 删除新闻评论回复举报
export function delReport(data) {
  return request({
    url: '/news/newsReport/delete',
    method: 'post',
    data: data
  })
}
